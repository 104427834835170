.badge {
	display: inline-block;
	font-size: 12px;
	font-weight: 500;
	text-align: center;
    white-space: nowrap;
    text-transform: uppercase;
	vertical-align: baseline;
	border-radius: .375rem;
	position: absolute;
	padding: 1vh 1vw;
	border-radius: 100px;
	transform: translate(-50%);
	margin-bottom: calc(1vw + 5vh);
	margin-left: calc(-2vw + 5vh);

}
.badge-virksomheder {
	display: inline-block;
	font-size: 12px;
	font-weight: 500;
	text-align: center;
    white-space: nowrap;
    text-transform: uppercase;
	vertical-align: baseline;
	border-radius: .375rem;
	position: absolute;
	padding: 1vh 1vw;
	border-radius: 100px;
	transform: translate(-50%);
	margin-bottom: calc(1vw + 5vh);
	margin-left: calc(-2.3vw + 5vh);
}

.badge-primary {
	color: #004677;
	background-color: rgba(203,210,246,.5);
}

.badge-success {
    color: #1aae6f;
    background-color: rgba(147,231,195,.5);
}

.badge-danger {
	color: #f80031;
	background-color: rgba(251,175,190,.5);
}

.badge-info {
	color: #03acca;
	background-color: rgba(136,230,247,.5);
}
.badge-confirm {
	position: absolute;
	color: white;
	padding: 1vh 1vw;
	background-color: #29CC97;
	}