.buton {
  font: inherit;
  border: none;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  line-height: 21px;
  font-size: 14px;
  border-radius: 0px 30px 30px 0px;
  width: 280px;
  color: #ffffff;
  background: none;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  letter-spacing: 0.02em;
  transition: 0.25s all ease-in-out;
}

.buton:focus {
  outline: none;
}

.buton:disabled {
  background: #ccc;
  color: #888888;
  cursor: not-allowed;
  border: #ccc;
  box-shadow: none;
}

.buton:disabled:hover,
.buton:disabled:active {
  background: #ccc;
  color: #888888;
  border: #ccc;
}
