.signup-form {
  padding: 3rem 5rem 1rem 4rem;
  width: 900px;
  margin: 0 auto;
  border-radius: 10px;
  background: #F1F6F9;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

#customerHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-family: Mulish;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.3px;
  background-color:#ffffff;
  color: #004677;
  font-style: normal;
}
.signup-form h2 {
  text-align: left;
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #004677;
  margin-bottom: 35px;
}

.signup-form .input {
  padding: 0 3px 7px 3px;
}

.signup-form .input label {
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
}

.signup-form .input input {
  background-color: #F1F6F9;
  border: 2px solid #dadada;
  color: #1f3039;
  border-radius: 30px;
  font-size: 16px;
  padding: 0.25rem 1.9rem;
  box-sizing: border-box;
}

.signup-form input::placeholder {
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;
}
.signup-form .input textarea {
  padding: 0.55rem 1.8rem;
}

.signup-form button {
  text-transform: none;
  padding: 12px 9px;
  min-width: 100%;
  background: #004677;
  border: none;
  border-radius: 30px;
  margin: 0 3px;
  display: inline-block;
  box-shadow: none;
  font-weight: 700;
  transition: 0.25s all ease-in-out;
}

.signup-form button:hover {
  background: #0182df;
}

.signup-form .login-footer {
  text-align: center;
  padding: 20px 0;
}

.signup-form .login-footer a {
  text-decoration: none;
  color: #1f3039;
  transition: 0.25s all ease-in-out;
}

.auth-form .login-footer a:hover {
  color: #0076a5;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: minmax(100px auto);
  grid-gap: 6px;
  margin-top: 0.2rem;
  margin-bottom: 8px;
  padding-bottom: 10px;
}
.grid-item1 {
  grid-column: 1 / 7;
}
.grid-item2 {
  margin-top: 2rem;
  grid-column: 7 / 8;
  width: 95px;
}
.grid-item3 {
  margin-top: 2rem;
  margin-right: 6px;
  grid-column: 8 /13;
}

@media (min-width: 768px) {
  .signup-form {
    width: 80vw;
    height: 46.5rem;
  }
}
