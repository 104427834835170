.listed-items:hover {
    background: none;
}

.listed-otheritems:hover {
    background: none;
}

.chatpanel-messageList {
  overflow: hidden;
  overflow-y: scroll;
  max-width: 30%;
}

.message-chat {
    border-radius: 20px 0 20px 20px;
    background-color: #016087;
    text-align: center;
    padding: 17px;
    margin-bottom: 5px;
    margin-right: 5px;
    text-align: start;
    float: right;
    overflow: hidden;
    line-break: anywhere;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
}

.message-list {
  min-height: 50ex;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.message-text {
  display: flex;
}

.message-content {
  padding-top: 0px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.8px;
}

.message-user {
    flex: 1;
    justify-content: flex-start;
    font-weight: bold;
    white-space: pre;
}

.message-time {
    flex: 1;
    margin-left: 6px;
    justify-content: flex-end;
    align-items: flex-end;
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    color: #D6D6D6;
}

.message-role {
  font-weight: bold;
}

.chatHeader {
  height: 100px;
  margin-bottom: 20px;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
}

.message-otherchat {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    max-width: 70%;
    background-color: #EFEFEF;
    text-align: center;
    padding: 17px;
    margin-bottom: 5px;
    margin-right: 5px;
    text-align: start;
    overflow: hidden;
    line-break: anywhere;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #16002A;
    margin-top: 0px;
    float: left;
}

.message-otherlist {
  min-height: 50ex;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.message-othertext {
  display: flex;
}

.message-otheruser {
    flex: 2;
    justify-content: flex-start;
    font-weight: bold;
    white-space: pre;
}

.message-othertime {
    margin-right: 6px;
    text-align: right;
    justify-content: flex-end;
    align-items: flex-end;
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    color: #D6D6D6;
}

.message-otherrole {
  font-weight: bold;
  opacity: 0.7;
}
.message-othercontent {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.8px;
  color: #16002a;
}

@media (max-width: 768px) {
    .message-otherchat,
    .message-chat {
        width: 100%;
    }
}
