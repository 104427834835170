.modal {
  position: fixed;
  width: 100%;
  left: 5%;
  top: 5%;
  background: #F1F6F9;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  z-index: 200;
  max-height: 90vh;
  overflow-y: auto;
  margin-left: -9vw;
}

.modal__header {
  padding: 45px 10px 0 10px;
}

.modal__header h1 {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  font-family: "Muli", sans-serif;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #004677;
  margin-left: 2.3rem;
  text-align: left;
}

.modal__content {
  padding: 4px 1rem 1rem 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  text-align: center;
}

.modal__actions {
  padding-bottom: 2rem;
  text-align: center;
}

.modal__actions button {
  margin: 0 0.2rem;
  background: #004677;
  color: white;
  border: none;
}

.modal__actions button:only-child {
  border-radius: 30px;
}

.modal__actions button:hover,
button:active {
  background: #0182df;
  transition: 0.25s all ease-in-out;
  text-transform: none;
}

@media (min-width: 768px) {
  .modal {
    width: 30rem;
    left: calc((100% - 13rem) / 2);
  }
}
