.login-container {
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  position: absolute;
  background:#004677;
  height: 100%;
  width: 100%;
  padding: 2rem;
  justify-content: flex-start;
  align-items: center;
}

.logo {
  display: flex;
  width: 10%; height: auto; 
  position: absolute;
  justify-content: center;
  align-items: center;
}

@media (max-height: 1650px) {
  .logo {
    width: 25rem;
  }
}

@media (max-height: 1540px) {
  .logo {
    padding-top: 40px;
    width: 25rem;
  }
}

  .auth-form {
    margin-top: 13%;
    margin-bottom: 13%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    border-radius: 10px;
    padding: 2rem;
    background: #F1F6F9;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
  }


.auth-form h2 {
  text-align: center;
  padding-bottom: 20px;
}

.auth-form .input {
  padding-bottom: 15px;
}

.auth-form .input label {
  text-transform: none;
  color: #1f3039;
  font-size: 18px;
  font-weight: 700;
}

.auth-form .input input {
  background-color: #f1f6f9;
  border: none;
  color: #1f3039;
  border-radius: 5px;
  font-size: 16px;
  padding: 1rem 0.75rem;
  border: 1px solid #dfe0eb;
}

  .auth-form button {
    border-radius: 75px;
    text-transform: none;
    width: auto;
    padding: 12px 40px;
    min-width: 230px;
    background:#004677;
    border: none;
    border-radius: 5px;
    margin: 0 auto;
    display: block;
    box-shadow: none;
    font-weight: 700;
    transition: .25s all ease-in-out;
  }


.auth-form button:hover {
  background: #0061a7;
}

.auth-form .login-footer {
  text-align: center;
  padding: 20px 0;
}

.auth-form .login-footer a {
  text-decoration: none;
  color: #1f3039;
  transition: 0.25s all ease-in-out;
}

.auth-form .login-footer a:hover {
  color: #0076a5;
}

@media (min-width: 768px) {
  .auth-form {
    width: 40rem;
  }
}
.form-container {
  background-color: #F1F6F9;
  border-radius: 20px;
}