.sendmessage {
  margin-bottom: 3%;
  margin-left: 5%;
  width: 530px;
  background: #ffffff;
  border: 0.5px solid #bfbfbf;
  box-sizing: border-box;
  border-radius: 20px;
  padding-left: 12px;
  text-align: justify;
  outline: none;
  height: 6ex;
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.8px;
}
