#title {
    font-family: Arial, Helvetica, sans-serif;
  }
  
  #craftsmen {
      text-align: center;
      font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      border: 3px solid#ddd;
      width: 100%;
  }
  #craftsmen td, #craftsmen th {
      border: 1px solid #ddd;
      padding: 8px;
    }
    
    #craftsmen tr:nth-child(even){background-color: #f2f2f2;}
    
    #craftsmen tr:hover {background-color: #ddd;}
    
    #craftsmen th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: center;
      background-color: rgb(14, 103, 219);
      color: white;
    }
    #craftsmanHeader {
      background-color: #f1f1f1;
      padding: 30px;
      text-align: center;
      font-size: 35px;
    }
    #craftsmanDiv {
      margin-left: 33%;
      padding-top: 50px; 
      max-width: 100vw;
    }