.input {
  margin: 10px 0;
  width: 100%;
}

.input label {
  display: block;
  font-family: "Muli", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.2px;
  text-transform: none;
  text-align: left;
  color: #252733;
  margin: 10px 10px 5px 0;
}

.input input,
.input textarea {
  font: inherit;
  padding: 0.25rem 1.8rem;
  width: 100%;
  border-radius: 30px;
  box-sizing: border-box;
  height: 40px;
  border: 2px solid #dadada;
  background: #F1F6F9;
}
::placeholder {
  font-family: "Muli", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.2px;
}

.input .touched.invalid {
  border-color: #db0b5b;
}

.input input:focus,
.input textarea:focus {
  outline: none;
  border-color: rgb(14, 103, 219);
  color: rgb(14, 103, 219);
}

.input .css-yk16xz-control {
  background-color: #F1F6F9;
}

.input .css-1pahdxg-control {
  background-color: #F1F6F9;
}

.input .css-26l3qy-menu {
  background-color: #F1F6F9;
}

.input select {
  width: 100%;
  height: 30px;
  margin-right: 100%;
}

.input select:focus {
  height: 30px;
  border-color: rgb(14, 103, 219);
}

.datePicker {
  margin-right: 70%;
  width: 100%;
}

.customDatePicker,
.customDatePicker > div.react-datepicker-wrapper,
.customDatePicker
  > div
  > div.react-datepicker__input-container
  .customDatePicker
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}
