.paginator__controls {
  display: flex;
  justify-content: center;
}

.paginator__control {
  width: 5rem;
  padding: 0.25rem 0;
  margin: 0 1rem;
  border: 1px solid rgb(14, 103, 219);
  background: transparent;
  font: inherit;
  cursor: pointer;
  font-size: 1rem;
  color: rgb(14, 103, 219);
}

.paginator__control:hover,
.paginator__control:active {
  color: #fab83f;
  border-color: #fab83f;
}

.paginator__control:focus {
  outline: none;
}
