.chatpanel-messageList {
  margin: 10px auto;
  max-height: 550px;
  max-width: 75%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(82, 63, 105, 0.05);
}
/* sendMessage component and input*/
.container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 5px;
}

.item1 {
  grid-column: 1/6;
  margin-left: 0px;
}
.item2 {
  grid-column: 10/13;
  size: 20%;
}
.item2 #file-input {
  display: none;
}
.item2 img {
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin-left: 5rem;
  margin-top: 5px;
}

.chatpanel {
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(82, 63, 105, 0.05);
  margin-top: 2%;
  margin-left: 20%;
  margin-right: 20%;
}

@media (max-width: 768px) {
  .chatpanel-messageList {
    max-width: 100%;
    max-height: 550px;
  }
}
