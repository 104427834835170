.mobile-toggle {
  border: none;
  display: block;
  width: 2rem;
  cursor: pointer;
  top: 0;
  right: 0;
  position: absolute;
  background: #004677;
  padding: 4px;
  border-radius: 4px;
  z-index: 201;
  margin: 1rem;
}

.mobile-toggle__bar {
  margin: 4px 0;
  height: 3px;
  background: white;
  display: block;
}

@media (min-width: 768px) {
    .mobile-toggle {
        display: none;
    }
}