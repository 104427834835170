@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.dashboard-div {
    font-size: large;
    color: rgb(0, 0, 0)
}
.dashboard-overview {
    margin-top: 10%;
}

.dashboard-customers {
    margin-top: 2%;
    margin-left: 25%;
}
.dashboard-craftsmen {
    margin-top: 2%;
    margin-left: 25%;
}
.dashboard-projects {
    margin-top: 2%;
    margin-left: 25%;
}

.dashboard-container {
    background-color: #FFFFFF;
    border: 1px solid #DFE0EB;
    border-radius: 4;
    cursor: pointer;
    height: 70;
    max-width: 350;
    margin-right: 30;
    padding: 24px 32px 24px 32px;
}

.dashboard-welcome {
    font-weight: bold;
    text-align: left;
    line-height: 30.12px;
    font-size: 24px;
    color: #004677;
    font-family: Mulish;
    font-style: normal;
    display: ruby;
    margin-left: 30px;
    width: 30%;
}

.dashboard_cards {
    flex: 1;
    height: 100;
}
.dashboard_clients {
    color: rgb(14, 103, 219);
}

.dashboard_lable {
    flex: 1;
    text-align: center;
}

.dashboard_number_of {
    flex: 1;
    text-align: center;
    font-size: 1rem;
    text-decoration: 'underline';
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 70px;
    line-height: 105px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #004677;   
}

.dashboard_button_links {
    flex: 1;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: deeppink;

}

.profile_icon{
    height: 44px;
    width: 44px;
    border-radius: 40px;
    border: 2.5px solid #DFE0EB;
}

.dashboard_indicator {
    background-color: #DFE0EB;
    width: 3px;
    display: flex;
    height: 32px;
    margin-right: 32px;
}

.dashboard_icons{
    display: flex;
    width: 68%;
    place-content: flex-end;
    align-items: center;
    margin-right: 9%;
}
.dashboard_icons-signup{
    display: flex;
    width: 100%;
    place-content: flex-end;
    align-items: center;
    margin-right: 9%;
}
.dashboard_icons{
    display: flex;
    width: 68%;
    place-content: flex-end;
    align-items: center;
    margin-right: 9%;
}

.dropdown {
    background-color: white;
    position: absolute;
    overflow: hidden;
    width: 300px;
    z-index: 2;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  }
  .dropdown_headline {
    background-color: white;
    text-align: center;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    padding: 8px 12px;
  }
  
  li:hover {
    background-color: rgba(0, 0, 0, 0.14);
    cursor: pointer;
  }
  .some-page-wrapper {
    background: #FFFFFF;
    border-radius: 4px;
    height: 85vh;
  }
.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 40vh
  }
.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }
.blue-column {
    height: 100%;
  }
.green-column {
    height: 100%;
  }
.company-container {
    height: 139vh;
    width: 100%;
    display: -moz-box;
    display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}
.company-container-2 {
    height: 110vh;
    width: 100%;
    display: -moz-box;
    display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 0.5rem;
}
.company-flex-1 {
    width:100%;
    background: #F1F6F9;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 1rem;
}
.company-flex-2 {
  width:100%;
    position: relative;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    background: #F1F6F9;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 0.5rem;
    margin-bottom: 1rem;
}
.company-flex-3 {
  width:100%;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background: #F1F6F9;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 0.5rem;
    margin-bottom: 1rem;
}
.company-dashboard-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #004677;
    padding: 0 1rem;
}

.company-dashboard-cal-week {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #004677;
}
.solid {
    border-top: 4px solid #EBEFF2;
    width: 100%;
}
.company-cal-days {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
}
.company-days {
    width: 100%;
}
.company-day {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #334D6E;
    opacity: 0.5;
}
.company-date {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #4C5862;
    cursor: pointer
}
.company-day-today {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #016087;
}
.company-date-today {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #016087;
    cursor: pointer
}
.company-day-datepicker {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #334D6E;
    opacity: 0.5;
}
.company-date-datepicker {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    background-color:  #016087;
    border-radius: 30px;
    cursor: pointer
}
.company-container-projects {
    background: #F1F6F9;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 0.5rem;
    margin-bottom: 1rem;
}
.company-projectcard-info-customer {
    display: flex;
}
.company-projectcard-container {
    display: flex;
}
.company-projectcard-status-buttons {
    display: flex;
    flex:1;
    justify-content: center;
}
.company-projectcard-status-button {
    padding-top: 15px;
}
.company-projectcard-info{
    flex:10;
    display: flex;
    flex-direction: column;
}
.company-projectcard-info-date {
    display: flex;
}
.start-info-date-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #4C5862;
    opacity: 0.5;
    padding-right: 10px;
}
.start-info-date-date {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #4C5862;
}
.company-projectcard-status {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0.5rem;
}
.company-projectcard-info-customer-name {
    width: 150px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #707683;
    padding-left: 10px;
}
.company-projectcard-info-customer-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #4C5862;
    opacity: 0.5;
    padding-right: 10px;
}
.company-projectcard-info-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #016087;
}
.company-projectcard-info-customer-avatar {
    margin-top: 10px;
}

.image-cropper-dashboard {
    position: relative;
    width: 6%;
    height: 0;
    padding: 6% 0 0; /*Change padding and width to same values to enlarge picture */
    border-radius: 50%; 
    overflow: hidden;
    /* border: 1px solid gray; */ /*Add if border is needed for image*/
}
img.profile-pic-dashboard {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.company-projectcard-status-badge-pending {
    background: #FFB946;
    border-radius: 4px;
    text-align: center;
    padding: 0 10px;
}
.company-projectcard-status-badge-done {
    background: #29CC97;
    border-radius: 4px;
    text-align: center;
    padding: 0 10px;
}
.company-projectcard-status-badge-notstarted {
    background: #F7685B;
    border-radius: 4px;
    text-align: center;
    padding: 0 10px;
}
.company-status-badge-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}
.company-dashboard-overview-container-boxes-1 {
  display: flex;
}
.company-dashboard-overview-cardbox {
  width: 20vw;
}
.company-dashboard-body-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F1F6F9;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}
.percentage-pending {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #192A3E;
}
.percentage-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 8vh;
  letter-spacing: 0.01em;
  color: #2ED47A;
  top: 5%;
  width: 100%;
  height: 100%;
  right: 10%;
  display: flex;
  align-items: center; /** Y-axis align **/
  justify-content: center; /** X-axis align **/
}
.company-dashboard-body-container{
    position: relative;
}
.inner-circle-green{
  display:inline-block;  
  width: 10px;
  height:10px;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color:#2ED47A;
  background-color: rgba(0, 0, 0, 0);
  margin-right: 10px;
}
.inner-circle-red{
  display:inline-block;  
  width: 10px;
  height:10px;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color:#F7685B;
  background-color: rgba(0, 0, 0, 0);
  margin-right: 10px;
}

.inner-circle-yellow{
  display:inline-block;  
  width: 10px;
  height:10px;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color:#FFB946;
  background-color: rgba(0, 0, 0, 0);
  margin-right: 10px;
}

.circle-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

}