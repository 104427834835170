.placeholder_img {
    height: 10px;
    width: 10px;
    border-radius: 50%;
}

.li{
    padding: 0px;
}

.chat_card_name {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;    
    color: #252733;
    margin-bottom: 3px;
}

.chat_card_mes_preview {   
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    color: #A4A6B3;
}

.chat_card_time_preview {  
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    color: #252733;
}

.chat_card {
    width: 28%;
    background: #FFFFFF;
    overflow: auto;
    height: 720px;
    border-top-left-radius: 20px;
    border-bottom-left-radius:20px;
}

.chat_card_background {
    display: flex;
    margin-top: 37px;
    background: #FFFFFF;
    margin-left: 30px;
    margin-right: 9%;
    border-radius: 20px;
    box-shadow: 0px 4px rgba(0, 0, 0, 0.10);
    border: 1px solid #DFE0EB;
}

.chat_right_body {
    width: 73.8%;
}

.chat_header {
    background-color: #FFFFFF;
    height: max-content;
    padding-top: 16px;
    padding-bottom: 16px;
    border-top-right-radius: 20px;
}

.room_child {
    border: 1px solid #DFE0EB;
}


.room_child:active,
.room_child:hover {
    background: #016087;
}

.room_child:active .chat_card_name,
.room_child:hover .chat_card_name {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: #FFFFFF;
    margin-bottom: 3px;
}

.room_child:active .chat_card_time_preview,
.room_child:hover .chat_card_time_preview {
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    color: #FFFFFF;
}

.room_child:active .chat_card_mes_preview,
.room_child:hover .chat_card_mes_preview {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    color: #FFFFFF;
}

.chatpanel-messageList {
    overflow: hidden;
    overflow-y: scroll;
    max-width: 100%;
}

.chat-panel {
    margin-top: 26px;
}
