.send-quickmessage {
    border-radius: 2ex;
    font-size: large;
    float: left;
    margin-left: 2%;
    margin-bottom: 3%;
    border-color: blue;
    color: blue;
    background-color: transparent;
    outline: none;
}
