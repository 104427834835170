#title {
  font-family: Arial, Helvetica, sans-serif;
}

#customers {
  text-align: center;
  font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid#ddd;
  width: 50%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: rgb(14, 103, 219);
  color: white;
}

#customerHeader {
  background: none;
  background-color: #f1f1f1;
  padding: 30px;
  text-align: center;
  font-size: 35px;
}

#customersDiv {
  margin-left: 33%;
  padding-top: 50px;
  max-width: 100vw;
}

/*Customer Delete Modal Styles*/
.react-confirm-alert {
  position: relative;
  border-radius: 20px;
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.react-confirm-alert-body {
  width: 450px;
  height: 250px;
  left: 140px;
  top: 30%;
  position: relative;
  padding: 35px 35px 35px 45px;
}

.react-confirm-alert-body h1 {
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
  margin-top: 30px;
  margin-bottom: 30px;
}

.react-confirm-alert-button-group button {
  background: #f7685b;
}

.react-confirm-alert-button-group button:first-child {
  border-radius: 30px 0 0 30px;
  width: 25%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 20px;
  color: #ffffff;
  letter-spacing: 0.02em;
  margin-right: 0.3rem;
}

.react-confirm-alert-button-group button:nth-child(2) {
  background: #004677;
  border-radius: 0px 30px 30px 0px;
  width: 280px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 20px;
  line-height: 21px;
  color: #ffffff;
  letter-spacing: 0.02em;
}

.react-confirm-alert-button-group button:first-child:hover,
button:active {
  background: #f53b2a;
  transition: 0.25s all ease-in-out;
  text-transform: none;
}

.react-confirm-alert-button-group button:nth-child(2):hover,
button:active {
  background: #0182df;
  transition: 0.25s all ease-in-out;
  text-transform: none;
}
.react-confirm-alert-body-message {
  font-family: 'Muli', sans-serif;
  color: #f53b2a;
}
