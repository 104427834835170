.projectfeed__status {
  width: 90%;
  margin: 1rem auto;
}

.projectfeed__status form {
  display: flex;
  align-items: center;
}

.projectfeed__status form * {
  margin: 0 0.5rem;
}

.projectfeed__control {
  text-align: center;
}

.new-project__preview-image {
  width: 15rem;
  height: 7rem;
}

@media (min-width: 768px) {
  .projectfeed__status {
    width: 30rem;
  }
}

.notificationBell {
  width: max-content;
  cursor: pointer;
}
.notificationBell_circle {
  font-size: medium;
  text-align: center;
  color: white;
  background: red;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  padding:1;
  margin:2;
  display:"inline-block";
}

.dropdown {
  background-color: white;
  position: absolute;
  overflow: hidden;
  width: 300px;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
}
.dropdown_headline {
  background-color: white;
  text-align: center;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
li {
  padding: 8px 12px;
}

li:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}
.projectfeed-scrollview {
  overflow: auto;
}