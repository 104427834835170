.main-nav__logo {
  padding-top: 60px;
  padding-bottom: 50px;
  padding-left: 32px;
}

.main-nav a:active,
.main-nav a:hover
 {
  outline: none;
}

.small-icon{
  margin-right: 32px;
}

.spacer {
  flex: 1;
}

.main-nav__items {
  list-style: none;
  padding: 0;
}

@media (min-width: 768px) {
  .main-nav__items {
    display: contents;
  }
}
