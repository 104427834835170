
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital@0;1&display=swap');

.image-cropper {
    position: relative;
    width: 13%;
    height: 0;
    padding: 13% 0 0; /*Change padding and width to same values to enlarge picture */
    border-radius: 50%; 
    overflow: hidden;
}
img.profile-pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-cropper-projectpage {
    position: relative;
    width: 10%;
    height: 0;
    padding: 10% 0 0; /*Change padding and width to same values to enlarge picture */
    border-radius: 50%; 
    overflow: hidden;
    /* border: 1px solid gray; */ /*Add if border is needed for image*/
}
img.profile-pic-projectpage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.td-avatar {
    display: flex;
    align-items: center;
    justify-content: left;
    height:10vh;
    width:22vw;
}
.td-avatar-projectpage {
    display: flex;
    align-items: center;
    justify-content: left;
    height:10vh;
    width:28vw;
}
.td-text {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding:0 0px 0px 20px;
}
.td-text-virksomheder {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding:0 0px 0px 0px;
}
.td-name {
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    padding-bottom: 5px;
    text-align: start;
    overflow: hidden;
    text-overflow:ellipsis;
}
.td-name-virksomheder {
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    padding-bottom: 5px;
}
.td-email {
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #C5C7CD;
}
.td-email-virksomheder {
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #C5C7CD;
}
.td-phone {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    text-align: start;
    width: 30%;
}
.td-status {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}
.th-header {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: black;
    text-align: start;
}
.th-header-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: black;
    text-align: start;
}
.td-default {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    width: 15%;
    text-align: start;
}
.td-default-virksomheder-jobtitel {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    width: 25%;
    text-align: start;
}
.td-default-virksomheder-phone {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    width: 25%;
    text-align: start;
}
.td-default-date {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    width: 19%;
    text-align: start;
}
.td-default-status {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    width: 20%;
    text-align: start;
}
.td-default-info {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    width: 5%;
    text-align: start;
}
.td-virksomheder {
    align-items: center;
    justify-content: left;
    width: 30%;
}
.td-virksomheder-address {
    align-items: center;
    justify-content: left;
    width: 30%;
    text-align: start;
}
button.MuiButtonBase-root.MuiIconButton-root.icon-info-squarenew {
    margin-left: 0px!important;
    margin-right:10px!important;
}

.MuiIconButton-root {
    padding: 0!important;
    margin-left: 12px!important;
}