.project {
  margin: 1rem 0;
  border: 1px solid #0d10d3;
  border-radius: 5px;
  padding: 0.5rem;
}

.project__meta {
  font-size: 1rem;
  color: #707070;
  margin: 0;
}

.project__title {
  font-size: 1.5rem;
  margin: 1rem 0;
  color: rgb(14, 103, 219);
}

.project__image {
  height: 15rem;
  width: 100%;
}

.project__actions {
  text-align: right;
}

@media (min-width: 768px) {
  .project {
    padding: 1rem;
    width: 40rem;
    margin-left: auto;
    margin-right: auto;
  }
}
