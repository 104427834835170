.Toastify__toast-container {
    width: auto;
}

.chatLogo{
    padding-right: 20px;
}

.notification_projects{
    display: flex;
    background-color: white;
    width: 100%;
}

.dashboard-user-name{
    font-weight: 600;
    font-size: 14px;
    color: rgb(37,39,51,1);
    font-family: Mulish;
    font-style: normal;
}

.Toastify__toast-container .Toastify__toast--error {
    border-radius: 5px;
    background: #FF3E58;
    padding: 6px 12px;
    font-family: 'Muli', sans-serif;
    font-weight: bold;
}

.notificationBell {
    width: max-content;
    cursor: pointer;
    padding-right: 16.5px;
  }
  .notificationBell_circle {
    font-size: 10px;
    text-align: center;
    color: white;
    background: linear-gradient(190deg, rgba(0,77,107,1) 0%, rgba(0,0,0,1) 100%);;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    display:"inline-block";
    position: absolute;
    margin-left: 15px;
}

@media (max-width: 768px) {
    .page-container {
        padding-left: 0 !important;
    }
}
