@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:ital@0;1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
* {
  box-sizing: border-box;
}

body {
  font-family: 'Muli', sans-serif;
  background: #FFFFFF;
}

.main-header {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.content {
  margin-top: 1.5rem;
  padding: 1rem;
  background-color:#FFFFFF;
}

@media (min-width: 768px) {
  .content {
    margin-top: 1.5rem;
    padding: 1rem 2rem;
  }
}

.backdrop {
  width: 100%;
  height: 100vh;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  transition: opacity 0.3s ease-out;
  opacity: 1;
  background: rgba(0,0,0,.5);
}

.toolbar {
    align-items: initial;
    height: 100%;
    background: #004677;
    width: 255px;
}

@media (max-width: 768px) {
    .toolbar {
        display: none;
    }
}

.navigation-item {
  padding: 0 0;
}

.indicator {
  background-color: #004677;
  width: 3px;
  padding: 0px 0px;
  display: flex;
}

.bar-item {
  display: inline-flex;
  align-items: center;
}

.navigation-item a {
  outline: none;
  height: 56px;
  line-height: normal;
  text-decoration: none;
  color: #A4A6B3;
  width: 100%;
  display: flex;
  border-radius: 6px;
  transition: .25s all ease-in-out;
  font-weight: 700;
  font-family: 'Muli', sans-serif;
}

.logoNavbar{
  fill:  #9FA2B4;
  margin-left: 32px;
  margin-right: 25px;
}

.navigation-item.mobile {
  font-size: 1.5rem;
  margin: 0;
}

.navigation-item.mobile a {
  color: white;
}

.navigation-item a:hover,
.navigation-item a.active
{
  background: rgba(159, 161, 180, 0.1);
  color: #DDE2FF;
}

.navigation-item a:hover .logoNavbar,
.navigation-item a.active .logoNavbar {
fill: #DDE2FF;
}

.navigation-item a.active .indicator {
background: #DDE2FF;
}

.navigation-item:last-of-type {
  padding-bottom: 0;
}

.navigation-item button {
  text-decoration: none;
  color: #A4A6B3;
  background: transparent;
  padding: 0.5rem 2rem;
  width: 90%;
  display: block;
  border-radius: 6px;
  border: 2px solid white;
  cursor: pointer;
  transition: .25s all ease-in-out;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Muli', sans-serif;
}

.navigation-item:last-of-type {
	padding-bottom: 0;
	position: absolute;
	left: 0;
	bottom: 20px;
  right: 0;
  display: flex;
  place-content: center;
}

.navigation-item button:hover,
.navigation-item button:active {
  background: #DDE2FF;
  color: #004677;
}

@media (max-height: 650px) {
  .navigation-item a {
      padding: 0rem;
      padding-right: .5rem;
  }
}

@media (max-height: 540px) {
  .navigation-item a {
      padding: .15rem;
  }
}

.main-nav__logo {
  padding-top: 60px;
  padding-bottom: 50px;
  padding-left: 32px;
}

.main-nav a:active,
.main-nav a:hover
 {
  outline: none;
}

.small-icon{
  margin-right: 32px;
}

.spacer {
  flex: 1 1;
}

.main-nav__items {
  list-style: none;
  padding: 0;
}

@media (min-width: 768px) {
  .main-nav__items {
    display: contents;
  }
}

.mobile-toggle {
  border: none;
  display: block;
  width: 2rem;
  cursor: pointer;
  top: 0;
  right: 0;
  position: absolute;
  background: #004677;
  padding: 4px;
  border-radius: 4px;
  z-index: 201;
  margin: 1rem;
}

.mobile-toggle__bar {
  margin: 4px 0;
  height: 3px;
  background: white;
  display: block;
}

@media (min-width: 768px) {
    .mobile-toggle {
        display: none;
    }
}
.mobile-nav {
  background: #004677;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 30rem;
  max-width: 100%;
  box-shadow: 1px 0 8px rbga(0, 0, 0, 0.26);
  transition: transform 0.3s ease-out;
  transform: translateX(-100%);
  z-index: 200;
}

.mobile-nav.open {
  transform: translateX(0);
}

.mobile-nav__items {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.mobile-nav__items.mobile {
    flex-direction: column;
}

:root {
  --BtnPrimary: #db0b5b;
}

.button {
  font: inherit;
  border: none;
  font-size: 14px;
  border-radius: 5px;
  color: #fff;
  background: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-transform: uppercase;
  box-shadow: none;
  font-weight: 700;
  transition: 0.25s all ease-in-out;
  text-transform: none;
}

.button:focus {
  outline: none;
}

.button:disabled {
  background: #ccc;
  color: #888888;
  cursor: not-allowed;
  border: #ccc;
  box-shadow: none;
}

.button:disabled:hover,
.button:disabled:active {
  background: #ccc;
  color: #888888;
  border: #ccc;
}

.button:hover,
.button:active {
  background: rgb(14, 103, 219);
  color: white;
}

.button--flat {
  border: none;
}

.button--flat:hover,
.button--flat:active {
  background: rgb(255, 255, 255);
  color: var(--BtnPrimary);
}

.button--raised {
  background: var(--BtnPrimary);
  color: white;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  background: #f7685b;
  border-radius: 4px;
}

.button--raised:hover,
.button--raised:active {
  background: #e68699;
}

.button--raised-outlined {
  background: transparent;
  border: 2px solid var(--BtnPrimary);
  color: var(--BtnPrimary);
}

.button--raised-outlined:hover,
.button--raised-outlined:active {
  background: var(--BtnPrimary);
  color: #fff;
}

.button.button--accent {
  border-color: #fab83f;
  color: #fab83f;
}

.button--accent:hover,
.button--accent:active {
  background: #fab83f;
  color: rgb(14, 103, 219);
}

.button--flat.button--accent:hover,
.button--flat.button--accent:active {
  background: rgb(14, 103, 219);
  color: #fab83f;
}

.button.button--accent.button--raised {
  background: #fab83f;
  color: rgb(14, 103, 219);
}

.button--raised.button--accent:hover,
.button--raised.button--accent:active {
  background: #fbc766;
}

.button.button--danger {
  color: #fff;
  background-color: #DB0B5B;
  width: 20%;
  border: none;
  border-radius: 30px 0px 0px 30px;
  font-family: 'Poppins';
  font-style: normal;
  line-height: 21px;
  font-weight: 600;
  letter-spacing: 0.02em;
  padding: 10px 20px;
}

.button--danger:hover,
.button--danger:active {
  background: #f30560;
  color: white;
}

.button--flat.button--danger:hover,
.button--flat.button--danger:active {
  background: #f30560;
  color: #fff;
}

.button.button--danger.button--raised {
  background: #a30000;
  color: white;
}

.button--raised.button--danger:hover,
.button--raised.button--danger:active {
  background: #c00000;
}

.button.button--success {
  border-color: #00b359;
  color: #00b359;
}

.button--success:hover,
.button--success:active {
  background: #00b359;
  color: white;
}

.button--flat.button--success:hover,
.button--flat.button--success:active {
  background: rgba(0, 179, 90, 0.3);
  color: #00b359;
}

.button.button--success.button--raised {
  background: #00b359;
  color: white;
}

.button--raised.button--success:hover,
.button--raised.button--success:active {
  background: #00a151;
}

.button--card-raised {
  background: var(--BtnPrimary);
  color: white;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  background: #004677;
  border-radius: 4px;
}

.button--card-raised:hover,
.button--card-raised:active {
  background: #0182df;
}

.buton {
  font: inherit;
  border: none;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  line-height: 21px;
  font-size: 14px;
  border-radius: 0px 30px 30px 0px;
  width: 280px;
  color: #ffffff;
  background: none;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  letter-spacing: 0.02em;
  transition: 0.25s all ease-in-out;
}

.buton:focus {
  outline: none;
}

.buton:disabled {
  background: #ccc;
  color: #888888;
  cursor: not-allowed;
  border: #ccc;
  box-shadow: none;
}

.buton:disabled:hover,
.buton:disabled:active {
  background: #ccc;
  color: #888888;
  border: #ccc;
}

.modal {
  position: fixed;
  width: 100%;
  left: 5%;
  top: 5%;
  background: #F1F6F9;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  z-index: 200;
  max-height: 90vh;
  overflow-y: auto;
  margin-left: -9vw;
}

.modal__header {
  padding: 45px 10px 0 10px;
}

.modal__header h1 {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  font-family: "Muli", sans-serif;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #004677;
  margin-left: 2.3rem;
  text-align: left;
}

.modal__content {
  padding: 4px 1rem 1rem 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  text-align: center;
}

.modal__actions {
  padding-bottom: 2rem;
  text-align: center;
}

.modal__actions button {
  margin: 0 0.2rem;
  background: #004677;
  color: white;
  border: none;
}

.modal__actions button:only-child {
  border-radius: 30px;
}

.modal__actions button:hover,
button:active {
  background: #0182df;
  transition: 0.25s all ease-in-out;
  text-transform: none;
}

@media (min-width: 768px) {
  .modal {
    width: 30rem;
    left: calc((100% - 13rem) / 2);
  }
}

.project {
  margin: 1rem 0;
  border: 1px solid #0d10d3;
  border-radius: 5px;
  padding: 0.5rem;
}

.project__meta {
  font-size: 1rem;
  color: #707070;
  margin: 0;
}

.project__title {
  font-size: 1.5rem;
  margin: 1rem 0;
  color: rgb(14, 103, 219);
}

.project__image {
  height: 15rem;
  width: 100%;
}

.project__actions {
  text-align: right;
}

@media (min-width: 768px) {
  .project {
    padding: 1rem;
    width: 40rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.input {
  margin: 10px 0;
  width: 100%;
}

.input label {
  display: block;
  font-family: "Muli", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.2px;
  text-transform: none;
  text-align: left;
  color: #252733;
  margin: 10px 10px 5px 0;
}

.input input,
.input textarea {
  font: inherit;
  padding: 0.25rem 1.8rem;
  width: 100%;
  border-radius: 30px;
  box-sizing: border-box;
  height: 40px;
  border: 2px solid #dadada;
  background: #F1F6F9;
}
::-webkit-input-placeholder {
  font-family: "Muli", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.2px;
}
::-ms-input-placeholder {
  font-family: "Muli", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.2px;
}
::placeholder {
  font-family: "Muli", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.2px;
}

.input .touched.invalid {
  border-color: #db0b5b;
}

.input input:focus,
.input textarea:focus {
  outline: none;
  border-color: rgb(14, 103, 219);
  color: rgb(14, 103, 219);
}

.input .css-yk16xz-control {
  background-color: #F1F6F9;
}

.input .css-1pahdxg-control {
  background-color: #F1F6F9;
}

.input .css-26l3qy-menu {
  background-color: #F1F6F9;
}

.input select {
  width: 100%;
  height: 30px;
  margin-right: 100%;
}

.input select:focus {
  height: 30px;
  border-color: rgb(14, 103, 219);
}

.datePicker {
  margin-right: 70%;
  width: 100%;
}

.customDatePicker,
.customDatePicker > div.react-datepicker-wrapper,
.customDatePicker
  > div
  > div.react-datepicker__input-container
  .customDatePicker
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

.paginator__controls {
  display: flex;
  justify-content: center;
}

.paginator__control {
  width: 5rem;
  padding: 0.25rem 0;
  margin: 0 1rem;
  border: 1px solid rgb(14, 103, 219);
  background: transparent;
  font: inherit;
  cursor: pointer;
  font-size: 1rem;
  color: rgb(14, 103, 219);
}

.paginator__control:hover,
.paginator__control:active {
  color: #fab83f;
  border-color: #fab83f;
}

.paginator__control:focus {
  outline: none;
}

.loader {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .loader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid rgb(14, 103, 219);
    border-radius: 50%;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgb(14, 103, 219) transparent;
  }
  .loader div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .loader div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .loader div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.projectfeed__status {
  width: 90%;
  margin: 1rem auto;
}

.projectfeed__status form {
  display: flex;
  align-items: center;
}

.projectfeed__status form * {
  margin: 0 0.5rem;
}

.projectfeed__control {
  text-align: center;
}

.new-project__preview-image {
  width: 15rem;
  height: 7rem;
}

@media (min-width: 768px) {
  .projectfeed__status {
    width: 30rem;
  }
}

.notificationBell {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  cursor: pointer;
}
.notificationBell_circle {
  font-size: medium;
  text-align: center;
  color: white;
  background: red;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  padding:1;
  margin:2;
  display:"inline-block";
}

.dropdown {
  background-color: white;
  position: absolute;
  overflow: hidden;
  width: 300px;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
}
.dropdown_headline {
  background-color: white;
  text-align: center;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
li {
  padding: 8px 12px;
}

li:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}
.projectfeed-scrollview {
  overflow: auto;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  width: 90%;
  margin-left: 30px;
  margin-top: 37px;
  margin-bottom: 50px;
  background: #ffffff;

  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 8px;
}
.dashboard {
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  width: 98%;
  margin: 0.5rem;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}
.overview {
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  width: 90%;
  margin-left: 30px;
  margin-top: 37px;
  margin-bottom: 50px;
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 0.5rem 2.5rem;
  background-color: #F1F6F9;
  border-radius: 0.5rem 0.5rem 0 0;
}
.dashboard .card-header-dashboard {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 0.5rem 2.5rem;
  background-color: #F1F6F9;
  border-radius: 0.5rem 0.5rem 0 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 0.68vw;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #90a0b7;
}
.overview .card-header-overview {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 0.5rem 2.5rem;
  background-color: #F1F6F9;
  border-radius: 0.5rem 0.5rem 0 0;
}

.card .card-header .card-title {
  font-weight: 700;
  font-size: 1rem;
  color: #004677;
}
.card .card-header-dashboard .card-title-dashboard {
  font-weight: 700;
  font-size: 1rem;
  color: #004677;
}
.overview .card-header-overview .card-title-overview {
  font-weight: 700;
  font-size: 1rem;
  color: #004677;
}

.card .card-header .card-buttons {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  flex-wrap: wrap;
}
.dashboard .card-header-dashboard .card-buttons-dashboard {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  flex-wrap: wrap;
}
.overview .card-header-overview .card-buttons-overview {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  flex-wrap: wrap;
}

.card .card-body {
  background-color: #F1F6F9;
  border-radius: 0.5rem;
}
.dashboard .card-body-dashboard {
  background-color: #F1F6F9;
  border-radius: 0.5rem;
}
.overview .card-body-overview {
  background-color: #F1F6F9;
  border-radius: 0.5 0;
  padding: 2rem 1rem;
  height: 25vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  border-top: 1.5px solid #ebeff2;
}

.card.card-no-padding .card-body {
  padding: 2rem 0rem;
}
.dashboard.card-no-padding-dashboard .card-body-dashboard {
  padding: 2rem 0rem;
}
.overview.card-no-padding-overview .card-body-overview {
  padding: 2rem 0rem;
}
@media (max-width: 768px) {
  .card {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .dashboard {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .overview {
    width: 100%;
  }
}

.badge {
	display: inline-block;
	font-size: 12px;
	font-weight: 500;
	text-align: center;
    white-space: nowrap;
    text-transform: uppercase;
	vertical-align: baseline;
	border-radius: .375rem;
	position: absolute;
	padding: 1vh 1vw;
	border-radius: 100px;
	transform: translate(-50%);
	margin-bottom: calc(1vw + 5vh);
	margin-left: calc(-2vw + 5vh);

}
.badge-virksomheder {
	display: inline-block;
	font-size: 12px;
	font-weight: 500;
	text-align: center;
    white-space: nowrap;
    text-transform: uppercase;
	vertical-align: baseline;
	border-radius: .375rem;
	position: absolute;
	padding: 1vh 1vw;
	border-radius: 100px;
	transform: translate(-50%);
	margin-bottom: calc(1vw + 5vh);
	margin-left: calc(-2.3vw + 5vh);
}

.badge-primary {
	color: #004677;
	background-color: rgba(203,210,246,.5);
}

.badge-success {
    color: #1aae6f;
    background-color: rgba(147,231,195,.5);
}

.badge-danger {
	color: #f80031;
	background-color: rgba(251,175,190,.5);
}

.badge-info {
	color: #03acca;
	background-color: rgba(136,230,247,.5);
}
.badge-confirm {
	position: absolute;
	color: white;
	padding: 1vh 1vw;
	background-color: #29CC97;
	}

.image-cropper {
    position: relative;
    width: 13%;
    height: 0;
    padding: 13% 0 0; /*Change padding and width to same values to enlarge picture */
    border-radius: 50%; 
    overflow: hidden;
}
img.profile-pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-cropper-projectpage {
    position: relative;
    width: 10%;
    height: 0;
    padding: 10% 0 0; /*Change padding and width to same values to enlarge picture */
    border-radius: 50%; 
    overflow: hidden;
    /* border: 1px solid gray; */ /*Add if border is needed for image*/
}
img.profile-pic-projectpage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.td-avatar {
    display: flex;
    align-items: center;
    justify-content: left;
    height:10vh;
    width:22vw;
}
.td-avatar-projectpage {
    display: flex;
    align-items: center;
    justify-content: left;
    height:10vh;
    width:28vw;
}
.td-text {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding:0 0px 0px 20px;
}
.td-text-virksomheder {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding:0 0px 0px 0px;
}
.td-name {
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    padding-bottom: 5px;
    text-align: start;
    overflow: hidden;
    text-overflow:ellipsis;
}
.td-name-virksomheder {
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    padding-bottom: 5px;
}
.td-email {
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #C5C7CD;
}
.td-email-virksomheder {
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #C5C7CD;
}
.td-phone {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    text-align: start;
    width: 30%;
}
.td-status {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}
.th-header {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: black;
    text-align: start;
}
.th-header-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: black;
    text-align: start;
}
.td-default {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    width: 15%;
    text-align: start;
}
.td-default-virksomheder-jobtitel {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    width: 25%;
    text-align: start;
}
.td-default-virksomheder-phone {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    width: 25%;
    text-align: start;
}
.td-default-date {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    width: 19%;
    text-align: start;
}
.td-default-status {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    width: 20%;
    text-align: start;
}
.td-default-info {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    width: 5%;
    text-align: start;
}
.td-virksomheder {
    align-items: center;
    justify-content: left;
    width: 30%;
}
.td-virksomheder-address {
    align-items: center;
    justify-content: left;
    width: 30%;
    text-align: start;
}
button.MuiButtonBase-root.MuiIconButton-root.icon-info-squarenew {
    margin-left: 0px!important;
    margin-right:10px!important;
}

.MuiIconButton-root {
    padding: 0!important;
    margin-left: 12px!important;
}
.table {
  overflow-x: auto;
  display: block;
  width: 100%;
  border-radius: 8px;
}

.table table {
  width: 100%;
  color: white;
  margin: 0;
  text-align: center;
  border-collapse: collapse;
  border-radius: 8px;
}

.table thead th {
  padding: 0rem 2.5rem 1rem 2.5rem;
  background-color: #F1F6F9;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #9fa2b4;
  border-bottom: 1.5px solid #dfe0eb;
}

.table tbody td {
  color: #1f3039;
  padding: 1rem 1rem 1rem 2.5rem;
}

.table tbody tr:nth-child(1n) {
  border-bottom: 1.5px solid #dfe0eb;
}

.single_project_body {
  font-family: Arial;
  margin-top: 37px;
  padding-right: 33px;
  margin-left: 30px;
  display: flex;
}

.single_project_left_sub_headline{
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #252733;
  margin-bottom: 7px;
}

.single_project_left_date_text{
  font-family: Poppins;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  color: #4C5862;
  opacity: 0.6;
  margin-right: 6px;
}

.single_project_left_date{
  font-family: Poppins;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  color: #4C5862;
}

.single_project_header {
  font-size: 20px;
  color: #252733;;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
}

.single_project_leftcolumn {   
  float: left;
  width: 47%;
  background: #F1F6F9;
  margin-right: 23px;
  overflow-wrap: break-word;
  border-radius: 10px;
  border: 1px solid #DFE0EB;
  padding-left: 37px;
  min-height: 550px;
}

/* Right column */
.single_project_rightcolumn {
  float: left;
  width: 45%;
  background: #F1F6F9;
  overflow-wrap: break-word;
  border-radius: 10px;
  border: 1px solid #DFE0EB;
  padding-left: 37px;
  min-height: 550px;
}

/* Fake image */
.single_project_fakeimg {
  background-color: #aaa;
  width: 100%;
  padding: 20px;
}

/* Add a card effect for articles */
.single_project_card {
 margin-top: 44px;
 margin-bottom: 44px;
}

.single_project_headline {
  margin: 0px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #004677;
  margin-bottom: 33px;
  width: 70%;
}

.single_project_right_date {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #252733;
  margin: 0px;
  margin-bottom: 34px;
}

.single_project_left_header_items{
  display: flex;
  place-content: space-between;
}

.single_project_sub_headline{
  margin: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #323C47;
}

.single_project_text {
  color: #252733;
  font-family: Mulish;
  font-weight: 600;
  font-size: 14px;
  font-style: normal;
  margin: 0px;
  margin-bottom: 4px;
  width: 70%;
}

.single_project_sub_text {
  color: #C5C7CD;
  font-family: Mulish;
  font-weight: normal;
  font-size: 12px;
  font-style: normal;
  margin: 0px;
}

.single_project_right_content {
  display: flex;
  align-items: center;
}

.profile_icon_card {
height: 44px;
width: 44px;
margin-left: 8px;
border-radius: 30px;
}

.single_project_left_icon {
  height: 16px;
  width: 16px;
  margin-right: 5px;
  border-radius: 20px;
}

.single_project_left_name_text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: #707683;
  margin-right: 6px;
}

.single_project_chatlogo {
  cursor: pointer;
  display: flex;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

/* Clear floats after the columns */
.single_project_row:after {
  content: "";
  display: table;
  clear: both;
}

/* Footer */
.single_project_footer {
  padding: 20px;
  text-align: center;
  background: #ddd;
  margin-top: 20px;
}

/* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 800px) {
  .single_project_leftcolumn, .single_project_rightcolumn {   
    width: 100%;
    padding: 0;
  }
}

#table {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid#ddd;
  width: 50%;
}
#table td, #table th {
  border: 1px solid #ddd;
  padding: 8px;
}

#table tr:nth-child(even){background-color: #f2f2f2;}

#table tr:hover {background-color: #ddd;}

#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: rgb(14, 103, 219);
  color: white;
}

.card_milestone {
  background-color: white;
  border: 1px solid #DFE0EB;
  border-radius: 2ex;
  padding: 10pt;
}
.card_task {
  background-color: #F1F6F9;
  border: 1px solid #DFE0EB;
  border-radius: 2ex;
  padding: 15px;
  margin-bottom: 25px;
  display: flex;
  align-items: end;
}
.cards_overview {
  margin-top: 0ex;
  margin-left: 0%;
}
.tasks_overview {
  margin-left: 0%;
}
.polaroid {
  width: 40%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.polaroid_file {
  width: 100%;
}
.polaroid_container {
  text-align: center;
  padding: 5px 0;
}
.single-image-cropper {
  display: flex;
  position: relative;
    width: 45px;
    height: 0;
    padding: 45px 0 0; /*Change padding and width to same values to enlarge picture */
    border-radius: 50%; 
    overflow: hidden;
    margin-right: 26px;
    margin-left: 5px;
}
.single-image-pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.single1-image-cropper {
  display: flex;
  position: relative;
    width: 23px;
    height: 0;
    padding: 23px 0 0; /*Change padding and width to same values to enlarge picture */
    border-radius: 50%; 
    overflow: hidden;
    margin-right: 10px;
    margin-left: 5px;
}
.single1-image-pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.single1client-image-cropper {
  display: flex;
  position: relative;
    width: 47px;
    height: 0;
    padding: 47px 0 0; /*Change padding and width to same values to enlarge picture */
    border-radius: 50%; 
    overflow: hidden;
    margin-right: 10px;
    margin-left: 5px;
}
.single1client-image-pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.login-container {
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  position: absolute;
  background:#004677;
  height: 100%;
  width: 100%;
  padding: 2rem;
  justify-content: flex-start;
  align-items: center;
}

.logo {
  display: flex;
  width: 10%; height: auto; 
  position: absolute;
  justify-content: center;
  align-items: center;
}

@media (max-height: 1650px) {
  .logo {
    width: 25rem;
  }
}

@media (max-height: 1540px) {
  .logo {
    padding-top: 40px;
    width: 25rem;
  }
}

  .auth-form {
    margin-top: 13%;
    margin-bottom: 13%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    border-radius: 10px;
    padding: 2rem;
    background: #F1F6F9;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
  }


.auth-form h2 {
  text-align: center;
  padding-bottom: 20px;
}

.auth-form .input {
  padding-bottom: 15px;
}

.auth-form .input label {
  text-transform: none;
  color: #1f3039;
  font-size: 18px;
  font-weight: 700;
}

.auth-form .input input {
  background-color: #f1f6f9;
  border: none;
  color: #1f3039;
  border-radius: 5px;
  font-size: 16px;
  padding: 1rem 0.75rem;
  border: 1px solid #dfe0eb;
}

  .auth-form button {
    border-radius: 75px;
    text-transform: none;
    width: auto;
    padding: 12px 40px;
    min-width: 230px;
    background:#004677;
    border: none;
    border-radius: 5px;
    margin: 0 auto;
    display: block;
    box-shadow: none;
    font-weight: 700;
    transition: .25s all ease-in-out;
  }


.auth-form button:hover {
  background: #0061a7;
}

.auth-form .login-footer {
  text-align: center;
  padding: 20px 0;
}

.auth-form .login-footer a {
  text-decoration: none;
  color: #1f3039;
  transition: 0.25s all ease-in-out;
}

.auth-form .login-footer a:hover {
  color: #0076a5;
}

@media (min-width: 768px) {
  .auth-form {
    width: 40rem;
  }
}
.form-container {
  background-color: #F1F6F9;
  border-radius: 20px;
}
#title {
  font-family: Arial, Helvetica, sans-serif;
}

#customers {
  text-align: center;
  font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid#ddd;
  width: 50%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: rgb(14, 103, 219);
  color: white;
}

#customerHeader {
  background: none;
  background-color: #f1f1f1;
  padding: 30px;
  text-align: center;
  font-size: 35px;
}

#customersDiv {
  margin-left: 33%;
  padding-top: 50px;
  max-width: 100vw;
}

/*Customer Delete Modal Styles*/
.react-confirm-alert {
  position: relative;
  border-radius: 20px;
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.react-confirm-alert-body {
  width: 450px;
  height: 250px;
  left: 140px;
  top: 30%;
  position: relative;
  padding: 35px 35px 35px 45px;
}

.react-confirm-alert-body h1 {
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
  margin-top: 30px;
  margin-bottom: 30px;
}

.react-confirm-alert-button-group button {
  background: #f7685b;
}

.react-confirm-alert-button-group button:first-child {
  border-radius: 30px 0 0 30px;
  width: 25%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 20px;
  color: #ffffff;
  letter-spacing: 0.02em;
  margin-right: 0.3rem;
}

.react-confirm-alert-button-group button:nth-child(2) {
  background: #004677;
  border-radius: 0px 30px 30px 0px;
  width: 280px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 20px;
  line-height: 21px;
  color: #ffffff;
  letter-spacing: 0.02em;
}

.react-confirm-alert-button-group button:first-child:hover,
button:active {
  background: #f53b2a;
  transition: 0.25s all ease-in-out;
  text-transform: none;
}

.react-confirm-alert-button-group button:nth-child(2):hover,
button:active {
  background: #0182df;
  transition: 0.25s all ease-in-out;
  text-transform: none;
}
.react-confirm-alert-body-message {
  font-family: 'Muli', sans-serif;
  color: #f53b2a;
}

#title {
    font-family: Arial, Helvetica, sans-serif;
  }
  
  #craftsmen {
      text-align: center;
      font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      border: 3px solid#ddd;
      width: 100%;
  }
  #craftsmen td, #craftsmen th {
      border: 1px solid #ddd;
      padding: 8px;
    }
    
    #craftsmen tr:nth-child(even){background-color: #f2f2f2;}
    
    #craftsmen tr:hover {background-color: #ddd;}
    
    #craftsmen th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: center;
      background-color: rgb(14, 103, 219);
      color: white;
    }
    #craftsmanHeader {
      background-color: #f1f1f1;
      padding: 30px;
      text-align: center;
      font-size: 35px;
    }
    #craftsmanDiv {
      margin-left: 33%;
      padding-top: 50px; 
      max-width: 100vw;
    }
.signup-form {
  padding: 3rem 5rem 1rem 4rem;
  width: 900px;
  margin: 0 auto;
  border-radius: 10px;
  background: #F1F6F9;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

#customerHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-family: Mulish;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.3px;
  background-color:#ffffff;
  color: #004677;
  font-style: normal;
}
.signup-form h2 {
  text-align: left;
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #004677;
  margin-bottom: 35px;
}

.signup-form .input {
  padding: 0 3px 7px 3px;
}

.signup-form .input label {
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
}

.signup-form .input input {
  background-color: #F1F6F9;
  border: 2px solid #dadada;
  color: #1f3039;
  border-radius: 30px;
  font-size: 16px;
  padding: 0.25rem 1.9rem;
  box-sizing: border-box;
}

.signup-form input::-webkit-input-placeholder {
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;
}

.signup-form input::-ms-input-placeholder {
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;
}

.signup-form input::placeholder {
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;
}
.signup-form .input textarea {
  padding: 0.55rem 1.8rem;
}

.signup-form button {
  text-transform: none;
  padding: 12px 9px;
  min-width: 100%;
  background: #004677;
  border: none;
  border-radius: 30px;
  margin: 0 3px;
  display: inline-block;
  box-shadow: none;
  font-weight: 700;
  transition: 0.25s all ease-in-out;
}

.signup-form button:hover {
  background: #0182df;
}

.signup-form .login-footer {
  text-align: center;
  padding: 20px 0;
}

.signup-form .login-footer a {
  text-decoration: none;
  color: #1f3039;
  transition: 0.25s all ease-in-out;
}

.auth-form .login-footer a:hover {
  color: #0076a5;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: minmax(100px auto);
  grid-gap: 6px;
  margin-top: 0.2rem;
  margin-bottom: 8px;
  padding-bottom: 10px;
}
.grid-item1 {
  grid-column: 1 / 7;
}
.grid-item2 {
  margin-top: 2rem;
  grid-column: 7 / 8;
  width: 95px;
}
.grid-item3 {
  margin-top: 2rem;
  margin-right: 6px;
  grid-column: 8 /13;
}

@media (min-width: 768px) {
  .signup-form {
    width: 80vw;
    height: 46.5rem;
  }
}

.Toastify__toast-container {
    width: auto;
}

.chatLogo{
    padding-right: 20px;
}

.notification_projects{
    display: flex;
    background-color: white;
    width: 100%;
}

.dashboard-user-name{
    font-weight: 600;
    font-size: 14px;
    color: rgb(37,39,51,1);
    font-family: Mulish;
    font-style: normal;
}

.Toastify__toast-container .Toastify__toast--error {
    border-radius: 5px;
    background: #FF3E58;
    padding: 6px 12px;
    font-family: 'Muli', sans-serif;
    font-weight: bold;
}

.notificationBell {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    cursor: pointer;
    padding-right: 16.5px;
  }
  .notificationBell_circle {
    font-size: 10px;
    text-align: center;
    color: white;
    background: linear-gradient(190deg, rgba(0,77,107,1) 0%, rgba(0,0,0,1) 100%);;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    display:"inline-block";
    position: absolute;
    margin-left: 15px;
}

@media (max-width: 768px) {
    .page-container {
        padding-left: 0 !important;
    }
}

.dashboard-div {
    font-size: large;
    color: rgb(0, 0, 0)
}
.dashboard-overview {
    margin-top: 10%;
}

.dashboard-customers {
    margin-top: 2%;
    margin-left: 25%;
}
.dashboard-craftsmen {
    margin-top: 2%;
    margin-left: 25%;
}
.dashboard-projects {
    margin-top: 2%;
    margin-left: 25%;
}

.dashboard-container {
    background-color: #FFFFFF;
    border: 1px solid #DFE0EB;
    border-radius: 4;
    cursor: pointer;
    height: 70;
    max-width: 350;
    margin-right: 30;
    padding: 24px 32px 24px 32px;
}

.dashboard-welcome {
    font-weight: bold;
    text-align: left;
    line-height: 30.12px;
    font-size: 24px;
    color: #004677;
    font-family: Mulish;
    font-style: normal;
    display: ruby;
    margin-left: 30px;
    width: 30%;
}

.dashboard_cards {
    flex: 1 1;
    height: 100;
}
.dashboard_clients {
    color: rgb(14, 103, 219);
}

.dashboard_lable {
    flex: 1 1;
    text-align: center;
}

.dashboard_number_of {
    flex: 1 1;
    text-align: center;
    font-size: 1rem;
    -webkit-text-decoration: 'underline';
            text-decoration: 'underline';
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 70px;
    line-height: 105px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #004677;   
}

.dashboard_button_links {
    flex: 1 1;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: deeppink;

}

.profile_icon{
    height: 44px;
    width: 44px;
    border-radius: 40px;
    border: 2.5px solid #DFE0EB;
}

.dashboard_indicator {
    background-color: #DFE0EB;
    width: 3px;
    display: flex;
    height: 32px;
    margin-right: 32px;
}

.dashboard_icons{
    display: flex;
    width: 68%;
    place-content: flex-end;
    align-items: center;
    margin-right: 9%;
}
.dashboard_icons-signup{
    display: flex;
    width: 100%;
    place-content: flex-end;
    align-items: center;
    margin-right: 9%;
}
.dashboard_icons{
    display: flex;
    width: 68%;
    place-content: flex-end;
    align-items: center;
    margin-right: 9%;
}

.dropdown {
    background-color: white;
    position: absolute;
    overflow: hidden;
    width: 300px;
    z-index: 2;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  }
  .dropdown_headline {
    background-color: white;
    text-align: center;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    padding: 8px 12px;
  }
  
  li:hover {
    background-color: rgba(0, 0, 0, 0.14);
    cursor: pointer;
  }
  .some-page-wrapper {
    background: #FFFFFF;
    border-radius: 4px;
    height: 85vh;
  }
.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 40vh
  }
.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1 1;
  }
.blue-column {
    height: 100%;
  }
.green-column {
    height: 100%;
  }
.company-container {
    height: 139vh;
    width: 100%;
    display: -webkit-flexbox;
    display: -moz-flex;
    display: flex;
    -moz-flex-direction: row;
    flex-direction: row;
}
.company-container-2 {
    height: 110vh;
    width: 100%;
    display: -webkit-flexbox;
    display: -moz-flex;
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    padding: 0 0.5rem;
}
.company-flex-1 {
    width:100%;
    background: #F1F6F9;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 1rem;
}
.company-flex-2 {
  width:100%;
    position: relative;
    -moz-flex: 1;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    background: #F1F6F9;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 0.5rem;
    margin-bottom: 1rem;
}
.company-flex-3 {
  width:100%;
    flex: 1 1;
    background: #F1F6F9;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 0.5rem;
    margin-bottom: 1rem;
}
.company-dashboard-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #004677;
    padding: 0 1rem;
}

.company-dashboard-cal-week {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #004677;
}
.solid {
    border-top: 4px solid #EBEFF2;
    width: 100%;
}
.company-cal-days {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
}
.company-days {
    width: 100%;
}
.company-day {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #334D6E;
    opacity: 0.5;
}
.company-date {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #4C5862;
    cursor: pointer
}
.company-day-today {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #016087;
}
.company-date-today {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #016087;
    cursor: pointer
}
.company-day-datepicker {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #334D6E;
    opacity: 0.5;
}
.company-date-datepicker {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    background-color:  #016087;
    border-radius: 30px;
    cursor: pointer
}
.company-container-projects {
    background: #F1F6F9;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 0.5rem;
    margin-bottom: 1rem;
}
.company-projectcard-info-customer {
    display: flex;
}
.company-projectcard-container {
    display: flex;
}
.company-projectcard-status-buttons {
    display: flex;
    flex:1 1;
    justify-content: center;
}
.company-projectcard-status-button {
    padding-top: 15px;
}
.company-projectcard-info{
    flex:10 1;
    display: flex;
    flex-direction: column;
}
.company-projectcard-info-date {
    display: flex;
}
.start-info-date-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #4C5862;
    opacity: 0.5;
    padding-right: 10px;
}
.start-info-date-date {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #4C5862;
}
.company-projectcard-status {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0.5rem;
}
.company-projectcard-info-customer-name {
    width: 150px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #707683;
    padding-left: 10px;
}
.company-projectcard-info-customer-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #4C5862;
    opacity: 0.5;
    padding-right: 10px;
}
.company-projectcard-info-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #016087;
}
.company-projectcard-info-customer-avatar {
    margin-top: 10px;
}

.image-cropper-dashboard {
    position: relative;
    width: 6%;
    height: 0;
    padding: 6% 0 0; /*Change padding and width to same values to enlarge picture */
    border-radius: 50%; 
    overflow: hidden;
    /* border: 1px solid gray; */ /*Add if border is needed for image*/
}
img.profile-pic-dashboard {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.company-projectcard-status-badge-pending {
    background: #FFB946;
    border-radius: 4px;
    text-align: center;
    padding: 0 10px;
}
.company-projectcard-status-badge-done {
    background: #29CC97;
    border-radius: 4px;
    text-align: center;
    padding: 0 10px;
}
.company-projectcard-status-badge-notstarted {
    background: #F7685B;
    border-radius: 4px;
    text-align: center;
    padding: 0 10px;
}
.company-status-badge-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}
.company-dashboard-overview-container-boxes-1 {
  display: flex;
}
.company-dashboard-overview-cardbox {
  width: 20vw;
}
.company-dashboard-body-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F1F6F9;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}
.percentage-pending {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #192A3E;
}
.percentage-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 8vh;
  letter-spacing: 0.01em;
  color: #2ED47A;
  top: 5%;
  width: 100%;
  height: 100%;
  right: 10%;
  display: flex;
  align-items: center; /** Y-axis align **/
  justify-content: center; /** X-axis align **/
}
.company-dashboard-body-container{
    position: relative;
}
.inner-circle-green{
  display:inline-block;  
  width: 10px;
  height:10px;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color:#2ED47A;
  background-color: rgba(0, 0, 0, 0);
  margin-right: 10px;
}
.inner-circle-red{
  display:inline-block;  
  width: 10px;
  height:10px;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color:#F7685B;
  background-color: rgba(0, 0, 0, 0);
  margin-right: 10px;
}

.inner-circle-yellow{
  display:inline-block;  
  width: 10px;
  height:10px;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color:#FFB946;
  background-color: rgba(0, 0, 0, 0);
  margin-right: 10px;
}

.circle-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

}
.chat-panel {
    overflow-x: auto;
    overflow-y: auto;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    margin-top: 37px;
}

.listed-items:hover {
    background: none;
}

.listed-otheritems:hover {
    background: none;
}

.chatpanel-messageList {
  overflow: hidden;
  overflow-y: scroll;
  max-width: 30%;
}

.message-chat {
    border-radius: 20px 0 20px 20px;
    background-color: #016087;
    text-align: center;
    padding: 17px;
    margin-bottom: 5px;
    margin-right: 5px;
    text-align: start;
    float: right;
    overflow: hidden;
    line-break: anywhere;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
}

.message-list {
  min-height: 50ex;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.message-text {
  display: flex;
}

.message-content {
  padding-top: 0px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.8px;
}

.message-user {
    flex: 1 1;
    justify-content: flex-start;
    font-weight: bold;
    white-space: pre;
}

.message-time {
    flex: 1 1;
    margin-left: 6px;
    justify-content: flex-end;
    align-items: flex-end;
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    color: #D6D6D6;
}

.message-role {
  font-weight: bold;
}

.chatHeader {
  height: 100px;
  margin-bottom: 20px;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
}

.message-otherchat {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    max-width: 70%;
    background-color: #EFEFEF;
    text-align: center;
    padding: 17px;
    margin-bottom: 5px;
    margin-right: 5px;
    text-align: start;
    overflow: hidden;
    line-break: anywhere;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #16002A;
    margin-top: 0px;
    float: left;
}

.message-otherlist {
  min-height: 50ex;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.message-othertext {
  display: flex;
}

.message-otheruser {
    flex: 2 1;
    justify-content: flex-start;
    font-weight: bold;
    white-space: pre;
}

.message-othertime {
    margin-right: 6px;
    text-align: right;
    justify-content: flex-end;
    align-items: flex-end;
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    color: #D6D6D6;
}

.message-otherrole {
  font-weight: bold;
  opacity: 0.7;
}
.message-othercontent {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.8px;
  color: #16002a;
}

@media (max-width: 768px) {
    .message-otherchat,
    .message-chat {
        width: 100%;
    }
}

.send-quickmessage {
    border-radius: 2ex;
    font-size: large;
    float: left;
    margin-left: 2%;
    margin-bottom: 3%;
    border-color: blue;
    color: blue;
    background-color: transparent;
    outline: none;
}

.sendmessage {
  margin-bottom: 3%;
  margin-left: 5%;
  width: 530px;
  background: #ffffff;
  border: 0.5px solid #bfbfbf;
  box-sizing: border-box;
  border-radius: 20px;
  padding-left: 12px;
  text-align: justify;
  outline: none;
  height: 6ex;
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.8px;
}

.chatpanel-messageList {
  margin: 10px auto;
  max-height: 550px;
  max-width: 75%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(82, 63, 105, 0.05);
}
/* sendMessage component and input*/
.container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 5px;
}

.item1 {
  grid-column: 1/6;
  margin-left: 0px;
}
.item2 {
  grid-column: 10/13;
  size: 20%;
}
.item2 #file-input {
  display: none;
}
.item2 img {
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin-left: 5rem;
  margin-top: 5px;
}

.chatpanel {
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(82, 63, 105, 0.05);
  margin-top: 2%;
  margin-left: 20%;
  margin-right: 20%;
}

@media (max-width: 768px) {
  .chatpanel-messageList {
    max-width: 100%;
    max-height: 550px;
  }
}

.calendar-div {
    margin-left: 30px;
    margin-right: 7.5%;
    margin-top: 37px;
}
.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2000;
  }

  .farver-forklaring {
    margin-right: 10%;
    text-align: right;
}

.farver-forklaring ul {
    list-style-type: none;
    display: inline-block;
    width: 100%;
}

.farver-forklaring ul li {
    display: inline-block;
    padding-left: 40px;
}

.farver-forklaring ul li #color-project:before {
    content: "•";
    color: rgb(14, 103, 219);
    padding-right:10px;
}

.farver-forklaring ul li #color-milestone:before {
    content: "•";
    color: rgb(145, 145, 245);
    padding-right:10px;
}

.farver-forklaring ul li #color-task:before {
    content: "•";
    color: rgb(7, 175, 241);
    padding-right:10px;
}

.farver-forklaring ul li #info-calendar:before {
    content: "OBS! ";
    color: rgb(0, 0, 0);
    padding-right:10px;
}

.placeholder_img {
    height: 10px;
    width: 10px;
    border-radius: 50%;
}

.li{
    padding: 0px;
}

.chat_card_name {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;    
    color: #252733;
    margin-bottom: 3px;
}

.chat_card_mes_preview {   
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    color: #A4A6B3;
}

.chat_card_time_preview {  
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    color: #252733;
}

.chat_card {
    width: 28%;
    background: #FFFFFF;
    overflow: auto;
    height: 720px;
    border-top-left-radius: 20px;
    border-bottom-left-radius:20px;
}

.chat_card_background {
    display: flex;
    margin-top: 37px;
    background: #FFFFFF;
    margin-left: 30px;
    margin-right: 9%;
    border-radius: 20px;
    box-shadow: 0px 4px rgba(0, 0, 0, 0.10);
    border: 1px solid #DFE0EB;
}

.chat_right_body {
    width: 73.8%;
}

.chat_header {
    background-color: #FFFFFF;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    padding-top: 16px;
    padding-bottom: 16px;
    border-top-right-radius: 20px;
}

.room_child {
    border: 1px solid #DFE0EB;
}


.room_child:active,
.room_child:hover {
    background: #016087;
}

.room_child:active .chat_card_name,
.room_child:hover .chat_card_name {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: #FFFFFF;
    margin-bottom: 3px;
}

.room_child:active .chat_card_time_preview,
.room_child:hover .chat_card_time_preview {
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    color: #FFFFFF;
}

.room_child:active .chat_card_mes_preview,
.room_child:hover .chat_card_mes_preview {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    color: #FFFFFF;
}

.chatpanel-messageList {
    overflow: hidden;
    overflow-y: scroll;
    max-width: 100%;
}

.chat-panel {
    margin-top: 26px;
}

.chat-panel {
    overflow-x: auto;
    overflow-y: auto;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    margin-top: 0px;
}

