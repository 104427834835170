.toolbar {
    align-items: initial;
    height: 100%;
    background: #004677;
    width: 255px;
}

@media (max-width: 768px) {
    .toolbar {
        display: none;
    }
}
