.backdrop {
  width: 100%;
  height: 100vh;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  transition: opacity 0.3s ease-out;
  opacity: 1;
  background: rgba(0,0,0,.5);
}
