.card {
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  width: 90%;
  margin-left: 30px;
  margin-top: 37px;
  margin-bottom: 50px;
  background: #ffffff;

  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 8px;
}
.dashboard {
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  width: 98%;
  margin: 0.5rem;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}
.overview {
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  width: 90%;
  margin-left: 30px;
  margin-top: 37px;
  margin-bottom: 50px;
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 0.5rem 2.5rem;
  background-color: #F1F6F9;
  border-radius: 0.5rem 0.5rem 0 0;
}
.dashboard .card-header-dashboard {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 0.5rem 2.5rem;
  background-color: #F1F6F9;
  border-radius: 0.5rem 0.5rem 0 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 0.68vw;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #90a0b7;
}
.overview .card-header-overview {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 0.5rem 2.5rem;
  background-color: #F1F6F9;
  border-radius: 0.5rem 0.5rem 0 0;
}

.card .card-header .card-title {
  font-weight: 700;
  font-size: 1rem;
  color: #004677;
}
.card .card-header-dashboard .card-title-dashboard {
  font-weight: 700;
  font-size: 1rem;
  color: #004677;
}
.overview .card-header-overview .card-title-overview {
  font-weight: 700;
  font-size: 1rem;
  color: #004677;
}

.card .card-header .card-buttons {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  flex-wrap: wrap;
}
.dashboard .card-header-dashboard .card-buttons-dashboard {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  flex-wrap: wrap;
}
.overview .card-header-overview .card-buttons-overview {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  flex-wrap: wrap;
}

.card .card-body {
  background-color: #F1F6F9;
  border-radius: 0.5rem;
}
.dashboard .card-body-dashboard {
  background-color: #F1F6F9;
  border-radius: 0.5rem;
}
.overview .card-body-overview {
  background-color: #F1F6F9;
  border-radius: 0.5 0;
  padding: 2rem 1rem;
  height: 25vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  border-top: 1.5px solid #ebeff2;
}

.card.card-no-padding .card-body {
  padding: 2rem 0rem;
}
.dashboard.card-no-padding-dashboard .card-body-dashboard {
  padding: 2rem 0rem;
}
.overview.card-no-padding-overview .card-body-overview {
  padding: 2rem 0rem;
}
@media (max-width: 768px) {
  .card {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .dashboard {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .overview {
    width: 100%;
  }
}
