.single_project_body {
  font-family: Arial;
  margin-top: 37px;
  padding-right: 33px;
  margin-left: 30px;
  display: flex;
}

.single_project_left_sub_headline{
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #252733;
  margin-bottom: 7px;
}

.single_project_left_date_text{
  font-family: Poppins;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  color: #4C5862;
  opacity: 0.6;
  margin-right: 6px;
}

.single_project_left_date{
  font-family: Poppins;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  color: #4C5862;
}

.single_project_header {
  font-size: 20px;
  color: #252733;;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
}

.single_project_leftcolumn {   
  float: left;
  width: 47%;
  background: #F1F6F9;
  margin-right: 23px;
  overflow-wrap: break-word;
  border-radius: 10px;
  border: 1px solid #DFE0EB;
  padding-left: 37px;
  min-height: 550px;
}

/* Right column */
.single_project_rightcolumn {
  float: left;
  width: 45%;
  background: #F1F6F9;
  overflow-wrap: break-word;
  border-radius: 10px;
  border: 1px solid #DFE0EB;
  padding-left: 37px;
  min-height: 550px;
}

/* Fake image */
.single_project_fakeimg {
  background-color: #aaa;
  width: 100%;
  padding: 20px;
}

/* Add a card effect for articles */
.single_project_card {
 margin-top: 44px;
 margin-bottom: 44px;
}

.single_project_headline {
  margin: 0px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #004677;
  margin-bottom: 33px;
  width: 70%;
}

.single_project_right_date {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #252733;
  margin: 0px;
  margin-bottom: 34px;
}

.single_project_left_header_items{
  display: flex;
  place-content: space-between;
}

.single_project_sub_headline{
  margin: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #323C47;
}

.single_project_text {
  color: #252733;
  font-family: Mulish;
  font-weight: 600;
  font-size: 14px;
  font-style: normal;
  margin: 0px;
  margin-bottom: 4px;
  width: 70%;
}

.single_project_sub_text {
  color: #C5C7CD;
  font-family: Mulish;
  font-weight: normal;
  font-size: 12px;
  font-style: normal;
  margin: 0px;
}

.single_project_right_content {
  display: flex;
  align-items: center;
}

.profile_icon_card {
height: 44px;
width: 44px;
margin-left: 8px;
border-radius: 30px;
}

.single_project_left_icon {
  height: 16px;
  width: 16px;
  margin-right: 5px;
  border-radius: 20px;
}

.single_project_left_name_text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: #707683;
  margin-right: 6px;
}

.single_project_chatlogo {
  cursor: pointer;
  display: flex;
  width: max-content;
}

/* Clear floats after the columns */
.single_project_row:after {
  content: "";
  display: table;
  clear: both;
}

/* Footer */
.single_project_footer {
  padding: 20px;
  text-align: center;
  background: #ddd;
  margin-top: 20px;
}

/* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 800px) {
  .single_project_leftcolumn, .single_project_rightcolumn {   
    width: 100%;
    padding: 0;
  }
}

#table {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid#ddd;
  width: 50%;
}
#table td, #table th {
  border: 1px solid #ddd;
  padding: 8px;
}

#table tr:nth-child(even){background-color: #f2f2f2;}

#table tr:hover {background-color: #ddd;}

#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: rgb(14, 103, 219);
  color: white;
}

.card_milestone {
  background-color: white;
  border: 1px solid #DFE0EB;
  border-radius: 2ex;
  padding: 10pt;
}
.card_task {
  background-color: #F1F6F9;
  border: 1px solid #DFE0EB;
  border-radius: 2ex;
  padding: 15px;
  margin-bottom: 25px;
  display: flex;
  align-items: end;
}
.cards_overview {
  margin-top: 0ex;
  margin-left: 0%;
}
.tasks_overview {
  margin-left: 0%;
}
.polaroid {
  width: 40%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.polaroid_file {
  width: 100%;
}
.polaroid_container {
  text-align: center;
  padding: 5px 0;
}
.single-image-cropper {
  display: flex;
  position: relative;
    width: 45px;
    height: 0;
    padding: 45px 0 0; /*Change padding and width to same values to enlarge picture */
    border-radius: 50%; 
    overflow: hidden;
    margin-right: 26px;
    margin-left: 5px;
}
.single-image-pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.single1-image-cropper {
  display: flex;
  position: relative;
    width: 23px;
    height: 0;
    padding: 23px 0 0; /*Change padding and width to same values to enlarge picture */
    border-radius: 50%; 
    overflow: hidden;
    margin-right: 10px;
    margin-left: 5px;
}
.single1-image-pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.single1client-image-cropper {
  display: flex;
  position: relative;
    width: 47px;
    height: 0;
    padding: 47px 0 0; /*Change padding and width to same values to enlarge picture */
    border-radius: 50%; 
    overflow: hidden;
    margin-right: 10px;
    margin-left: 5px;
}
.single1client-image-pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}