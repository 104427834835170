:root {
  --BtnPrimary: #db0b5b;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.button {
  font: inherit;
  border: none;
  font-size: 14px;
  border-radius: 5px;
  color: #fff;
  background: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-transform: uppercase;
  box-shadow: none;
  font-weight: 700;
  transition: 0.25s all ease-in-out;
  text-transform: none;
}

.button:focus {
  outline: none;
}

.button:disabled {
  background: #ccc;
  color: #888888;
  cursor: not-allowed;
  border: #ccc;
  box-shadow: none;
}

.button:disabled:hover,
.button:disabled:active {
  background: #ccc;
  color: #888888;
  border: #ccc;
}

.button:hover,
.button:active {
  background: rgb(14, 103, 219);
  color: white;
}

.button--flat {
  border: none;
}

.button--flat:hover,
.button--flat:active {
  background: rgb(255, 255, 255);
  color: var(--BtnPrimary);
}

.button--raised {
  background: var(--BtnPrimary);
  color: white;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  background: #f7685b;
  border-radius: 4px;
}

.button--raised:hover,
.button--raised:active {
  background: #e68699;
}

.button--raised-outlined {
  background: transparent;
  border: 2px solid var(--BtnPrimary);
  color: var(--BtnPrimary);
}

.button--raised-outlined:hover,
.button--raised-outlined:active {
  background: var(--BtnPrimary);
  color: #fff;
}

.button.button--accent {
  border-color: #fab83f;
  color: #fab83f;
}

.button--accent:hover,
.button--accent:active {
  background: #fab83f;
  color: rgb(14, 103, 219);
}

.button--flat.button--accent:hover,
.button--flat.button--accent:active {
  background: rgb(14, 103, 219);
  color: #fab83f;
}

.button.button--accent.button--raised {
  background: #fab83f;
  color: rgb(14, 103, 219);
}

.button--raised.button--accent:hover,
.button--raised.button--accent:active {
  background: #fbc766;
}

.button.button--danger {
  color: #fff;
  background-color: #DB0B5B;
  width: 20%;
  border: none;
  border-radius: 30px 0px 0px 30px;
  font-family: 'Poppins';
  font-style: normal;
  line-height: 21px;
  font-weight: 600;
  letter-spacing: 0.02em;
  padding: 10px 20px;
}

.button--danger:hover,
.button--danger:active {
  background: #f30560;
  color: white;
}

.button--flat.button--danger:hover,
.button--flat.button--danger:active {
  background: #f30560;
  color: #fff;
}

.button.button--danger.button--raised {
  background: #a30000;
  color: white;
}

.button--raised.button--danger:hover,
.button--raised.button--danger:active {
  background: #c00000;
}

.button.button--success {
  border-color: #00b359;
  color: #00b359;
}

.button--success:hover,
.button--success:active {
  background: #00b359;
  color: white;
}

.button--flat.button--success:hover,
.button--flat.button--success:active {
  background: rgba(0, 179, 90, 0.3);
  color: #00b359;
}

.button.button--success.button--raised {
  background: #00b359;
  color: white;
}

.button--raised.button--success:hover,
.button--raised.button--success:active {
  background: #00a151;
}

.button--card-raised {
  background: var(--BtnPrimary);
  color: white;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  background: #004677;
  border-radius: 4px;
}

.button--card-raised:hover,
.button--card-raised:active {
  background: #0182df;
}
