.calendar-div {
    margin-left: 30px;
    margin-right: 7.5%;
    margin-top: 37px;
}
.sticky {
    position: sticky;
    top: 0;
    z-index: 2000;
  }

  .farver-forklaring {
    margin-right: 10%;
    text-align: right;
}

.farver-forklaring ul {
    list-style-type: none;
    display: inline-block;
    width: 100%;
}

.farver-forklaring ul li {
    display: inline-block;
    padding-left: 40px;
}

.farver-forklaring ul li #color-project:before {
    content: "•";
    color: rgb(14, 103, 219);
    padding-right:10px;
}

.farver-forklaring ul li #color-milestone:before {
    content: "•";
    color: rgb(145, 145, 245);
    padding-right:10px;
}

.farver-forklaring ul li #color-task:before {
    content: "•";
    color: rgb(7, 175, 241);
    padding-right:10px;
}

.farver-forklaring ul li #info-calendar:before {
    content: "OBS! ";
    color: rgb(0, 0, 0);
    padding-right:10px;
}
