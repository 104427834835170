.navigation-item {
  padding: 0 0;
}

.indicator {
  background-color: #004677;
  width: 3px;
  padding: 0px 0px;
  display: flex;
}

.bar-item {
  display: inline-flex;
  align-items: center;
}

.navigation-item a {
  outline: none;
  height: 56px;
  line-height: normal;
  text-decoration: none;
  color: #A4A6B3;
  width: 100%;
  display: flex;
  border-radius: 6px;
  transition: .25s all ease-in-out;
  font-weight: 700;
  font-family: 'Muli', sans-serif;
}

.logoNavbar{
  fill:  #9FA2B4;
  margin-left: 32px;
  margin-right: 25px;
}

.navigation-item.mobile {
  font-size: 1.5rem;
  margin: 0;
}

.navigation-item.mobile a {
  color: white;
}

.navigation-item a:hover,
.navigation-item a.active
{
  background: rgba(159, 161, 180, 0.1);
  color: #DDE2FF;
}

.navigation-item a:hover .logoNavbar,
.navigation-item a.active .logoNavbar {
fill: #DDE2FF;
}

.navigation-item a.active .indicator {
background: #DDE2FF;
}

.navigation-item:last-of-type {
  padding-bottom: 0;
}

.navigation-item button {
  text-decoration: none;
  color: #A4A6B3;
  background: transparent;
  padding: 0.5rem 2rem;
  width: 90%;
  display: block;
  border-radius: 6px;
  border: 2px solid white;
  cursor: pointer;
  transition: .25s all ease-in-out;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Muli', sans-serif;
}

.navigation-item:last-of-type {
	padding-bottom: 0;
	position: absolute;
	left: 0;
	bottom: 20px;
  right: 0;
  display: flex;
  place-content: center;
}

.navigation-item button:hover,
.navigation-item button:active {
  background: #DDE2FF;
  color: #004677;
}

@media (max-height: 650px) {
  .navigation-item a {
      padding: 0rem;
      padding-right: .5rem;
  }
}

@media (max-height: 540px) {
  .navigation-item a {
      padding: .15rem;
  }
}
