.table {
  overflow-x: auto;
  display: block;
  width: 100%;
  border-radius: 8px;
}

.table table {
  width: 100%;
  color: white;
  margin: 0;
  text-align: center;
  border-collapse: collapse;
  border-radius: 8px;
}

.table thead th {
  padding: 0rem 2.5rem 1rem 2.5rem;
  background-color: #F1F6F9;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #9fa2b4;
  border-bottom: 1.5px solid #dfe0eb;
}

.table tbody td {
  color: #1f3039;
  padding: 1rem 1rem 1rem 2.5rem;
}

.table tbody tr:nth-child(1n) {
  border-bottom: 1.5px solid #dfe0eb;
}
